<template lang="pug">
  .kt-wizard-v1__nav
    .kt-wizard-v1__nav-items.kt-wizard-v1__nav-items--clickable
      .kt-wizard-v1__nav-item(
        v-for="(item, index) in navItems" :key="index"
        data-ktwizard-type="step"
        :data-ktwizard-state="getItemState(index)")
          .kt-wizard-v1__nav-body
            .kt-wizard-v1__nav-icon
              i(:class="item.icon")
            .kt-wizard-v1__nav-label {{ item.title | translate}}
</template>

<script>
export default {
  name: 'SetupNav',

  props: {
    currentStep: Number
  },

  data: () => ({
    navItems: [
      { icon: 'fa fa-globe-asia', title: 'pages.setup_wizard.company' },
      { icon: 'fa fa-database', title: 'pages.setup_wizard.data_and_sync' },
      { icon: 'flaticon2-user', title: 'pages.setup_wizard.super_admin' },
      { icon: 'flaticon2-avatar', title: 'pages.setup_wizard.employees' },
      { icon: 'fa fa-check-circle', title: 'pages.setup_wizard.summary' }
    ]
  }),

  methods: {
    getItemState (index) {
      return index > this.currentStep ? 'pending'
        : (index < this.currentStep ? 'done' : 'current')
    }
  }
}
</script>

<style lang="scss" scoped>
.kt-wizard-v1__nav-items{
  width: 100%;
}
</style>
